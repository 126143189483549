import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4b8aa978")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
_popScopeId()


import { useAccountListStore } from "@/store/pinia/gws-global-feature/employee/account/useAccountListStore";
import { useAccountFormStore } from "@/store/pinia/gws-global-feature/employee/account/useAccountFormStore";
import { formatDateTime } from "@/core/helpers/date-format";
import { ListItem } from "@/core/types/gws-master/employee/account/ListItem";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["delete-data", "edit-data"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useAccountListStore();
const formStore = useAccountFormStore();


const handleEditData = (item: ListItem) => {
  const editRow = {
    name: item.name,
    email: item.email,
    phone: item.phone,
    password: "",
    role_id: item.role.id,
  };
  formStore.setFormData(editRow);
  emits("edit-data", item.id);
};
const handleDeleteData = (event) => {
  emits("delete-data", event);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            type: "index",
            label: "No.",
            "min-width": "20",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            label: "Role",
            "min-width": "50",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.role.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "email",
            label: "Email",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "created_at",
            label: "Waktu Dibuat",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(formatDateTime)(scope.row.created_at)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "updated_at",
            label: "Waktu Diubah",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(formatDateTime)(scope.row.updated_at)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"])
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_unref(checkButtonPermission)('D'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-delete",
                      onClick: ($event: any) => (handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})